<template>
  <div class="p-8 container">
    <DxGridWithSearch
      title="Alternate Screenings"
      keyExpr="id"
      gridName="altScreenings"
      :dataSource="dataSource"
      :columns="columns"
      :selection="selectionMode"
      @selection-changed="handleSelect"
    />
    <div v-if="selectedKeys && selectedRowData">
      <CytologyTextPreview :selectedCodes="selectedRowData" />
    </div>
  </div>
</template>

<script>
import cytResultsService from "@/services/cytresults";
import DataSource from "devextreme/data/data_source";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import CytologyTextPreview from "./CytologyTextPreview.vue";
export default {
  name: "AlternateScreenings",
  props: {
    specimenId: {
      type: Number,
      required: true
    }
  },
  components: {
    DxGridWithSearch,
    CytologyTextPreview
  },
  data() {
    return {
      selected: [],
      selectionMode: {
        mode: "single"
      },
      selectedKeys: null,
      selectedRowData: null
    };
  },
  methods: {
    handleSelect(data) {
      this.selectedKeys = data.selectedRowKeys[0];
      cytResultsService.getOne(this.selectedKeys).then(data => {
        this.selectedRowData = data.cytResultDiagnosisCodes.map(code => ({
          id: code.cytDiagnosticCodeId,
          comment: code.comment
        }));
      });
    }
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: cytResultsService.createSearch(),
        filter: ["specimenId", "=", this.specimenId]
      });
    },
    columns() {
      return [
        {
          dataField: "cytResultType",
          caption: "Type"
        },
        {
          dataField: "isAbnormal",
          caption: "Abnormal"
        },
        {
          dataField: "resultedBy",
          caption: "Resulted By"
        },
        {
          dataField: "agreesWithPrevious"
        },
        {
          dataField: "maxSeverityLevel"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped></style>
