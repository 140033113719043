<template>
  <div v-shortkey="shortkeys" @shortkey="triggerShortkey">
    <div v-if="isReReporting" class="d-flex flex-column justify-content-center align-items-center">
      <div class="general-information mx-auto">
        <h5 class="my-2">Accession Editing</h5>
      </div>
      <div class="col-6 border rounded">
        <select-input class="col" :items="editTypes" label="Edit Type" v-model="editType" />
        <text-area-input
          class="col"
          label="Edit Reason"
          :resize="false"
          rows="5"
          v-model="reasonForChange"
        />
      </div>
    </div>

    <div class="general-information border-bottom d-flex justify-content-start" ref="header">
      <h5 class="my-2">General Information</h5>
    </div>
    <div class="form-row row-wrap">
      <contact
        :multiple="true"
        label="Provider"
        class="col col-lg-6"
        ref="provider"
        id="provider"
        name="provider"
        accessKey="p"
        v-model="specimen.contact"
        :validator="$v.specimen.contact"
      />
      <select-input
        v-model="specimen.protocolId"
        label="Protocol"
        id="protocol"
        name="protocol"
        class="text-input col col-lg-3 icd-select"
        ref="protocol"
        displayExpr="displayName"
        :items="protocolOptions"
        accessKey="r"
        searchMode="startswith"
      />
      <ICDCodes
        ref="icd"
        :options-limit="50"
        v-model="specimen.icdCodes"
        :validator="$v.specimen.icdCodes"
        :multiple="true"
        class="text-input col col-lg-3 icd-select"
        trackBy="id"
        :internal-search="false"
        :hide-selected="true"
        placeholder="Search Options"
        :close-on-select="false"
        :shortkey="true"
        @remove="removeICD"
        :checkModified="true"
      />
      <number-input
        v-model="specimen.numberOfSlides"
        label="No. of Slides"
        id="numberOfSlides"
        class="col col-lg-3"
        name="numberOfSlides"
        ref="numberOfSlides"
        accessKey="n"
      />

      <!-- <TextInput
        disabled
        :label="currentViewType"
        name="username"
        class="col col-lg-6"
        id="username"
        v-model="currentViewUser"
      /> -->
      <select-input
        class="col col-lg-2"
        :items="reviewTypes"
        v-if="isScreening"
        label="Forced Review"
        v-model="currentResult.cytReviewTypeRequestedId"
        id="forcedReview"
        name="forcedReview"
      />
      <template v-if="!isScreening">
        <select-input
          name="disagree"
          :items="yesNoOptions"
          class="col col-lg-2"
          v-model="disagree"
          v-if="!isReviewing"
          :disabled="isReviewing"
          label="Disagree"
        />
        <select-input
          label="Re-Screen"
          class="col col-lg-2"
          :items="yesNoOptions"
          name="reScreen"
          id="reScreen"
          v-model="reScreen"
        />
        <div
          class="col d-flex justify-content-end gap-x-4 align-items-start"
          :class="{
            'offset-3 col-lg-3': !lastScreening,
            'col-lg-6': lastScreening
          }"
        >
          <button @click="toggleAtlernate" class="btn btn-primary">Alt. Screenings</button>
          <button @click="handleAddReview" v-if="!isReviewing" class="btn btn-primary">
            Add <u>R</u>eview
          </button>
        </div>
      </template>
    </div>
    <template v-if="isReviewing || isScreening">
      <CytologyResultsBuilder
        :startingValues="prefillCodes"
        @submit="handleMacro"
        :resultedOn="resultDate"
      />
    </template>

    <div class="form-row justify-content-between" v-if="isPreviewOpen && previewText">
      <div class="col-12 d-flex justify-content-between align-items-end">
        <div class="col col-lg-3">
          <h4>{{ currentViewType }}: {{ currentViewUser }}</h4>
          <h5 class="mb-0">Resulted On: {{ currentResultedOn }}</h5>
        </div>
      </div>
      <div class="col-12">
        <CytologyTextPreview :disabled="true" v-if="previewText" :selectedCodes="previewText" />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-3">
      <div class="d-flex align-items-center justify-content-end ml-auto">
        <button
          class="btn btn-danger"
          v-if="lastScreening && isReviewing"
          @click="handleCancelReview"
        >
          Cancel
        </button>
        <button
          :disabled="formDisabled || isLoading"
          type="button"
          class="btn btn-primary ml-2 d-flex"
          id="saveBtn"
          @click="saveResults"
        >
          <loader size="small" v-if="isLoading"></loader>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
    <modal :status="isSystemMessageOpen">
      <h2>System Message</h2>
      <p v-if="isCytologist">Would you like to Re-Screen or Review this case?</p>
      <div class="d-flex justify-evenly">
        <button @click="() => handleMesage(1)" v-if="isCytologist" class="btn btn-primary">
          Re-screen
        </button>
        <button @click="() => handleMesage(2)" v-if="isCytologist" class="btn btn-primary mx-2">
          Review
        </button>
        <button @click="() => handleMesage(3)" class="btn btn-primary mx-2">View</button>
        <button @click="() => handleMesage(4)" class="btn btn-primary">Cancel</button>
      </div>
    </modal>
    <modal :status="isPromptOpen">
      <h2>Setup</h2>
      <form @submit.prevent="handleLoadSession" class="">
        <TextInput class="col" label="Username" name="username" disabled :value="username" />
        <DatePicker class="col" autoFocus label="Date" name="date" v-model="resultDate" />
        <SelectInput
          class="col"
          label="Re-Screen"
          name="reScreen"
          v-model="reScreen"
          :items="yesNoOptions"
        />
        <div class="d-flex pt-1 self-">
          <button type="submit" class="btn btn-primary ml-auto">Submit</button>
        </div>
      </form>
    </modal>

    <modal :status="isAlternateOpen" @close="isAlternateOpen = false">
      <AlternateScreenings :specimenId="specimen.id" />
    </modal>
  </div>
</template>
<script>
import DropdownService from "@/services/dropdown";
import { helpers, required } from "vuelidate/lib/validators";
import ProviderAPI from "@/services/providers";
import { mapGetters, mapState } from "vuex";
import { Contact, ICDCodes } from "../Selectors";
import TextAreaInput from "../../TextAreaInput.vue";
import eventBus, {
  fromBusEvent,
  ICD_ENGINE_FINISH,
  ICD_ENGINE_START,
  SAVE_FROM_CASE_HEADER,
  SPELL_CHECK_ACTION
} from "../../../modules/eventBus";
import { cloneDeep, unset } from "lodash";
import { filter, switchMap, take, map, takeWhile, exhaustMap } from "rxjs/operators";
import SelectInput from "@/components/common/SelectInput.vue";
import Modal from "@/components/common/Modal.vue";
import Loader from "@/components/common/Loader.vue";
import {
  getTextFromHtml,
  removeExtraDivs,
  scrollToElement,
  fixProviderPayload,
  isModalOpen,
  getAltKeys,
  getEditTypes,
  sortCaseInsensitive
} from "@/modules/helpers";
import { sortBy } from "lodash";
import { initialEditors } from "@/store/ApplicationSettings";
import {
  CaseEditTypeEnum,
  CytCodeGroupEnum,
  CytResultTypeEnum,
  CytReviewTypeEnum,
  MacroTypeEnum
} from "@/modules/enums";
import { interval, merge } from "rxjs";
import elementObserver from "@/modules/elementObserver";
import NumberInput from "@/components/common/NumberInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import cytologyResultService from "@/services/cytresults";
import cytologyEntityService, { CytologyEntities } from "@/services/cytology";
import CytologyTextPreview from "@/components/CytologyTextPreview.vue";
import CytologyResultsBuilder from "../CytologyResultsBuilder.vue";
import AlternateScreenings from "@/components/AlternateScreenings.vue";
import { format, parseISO } from "date-fns";

export default {
  name: "Cytology-Results-Form",
  props: {
    value: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    isUnlocked: Boolean
  },
  components: {
    CytologyResultsBuilder,
    AlternateScreenings,
    Contact,
    ICDCodes,
    TextAreaInput,
    TextInput,
    DatePicker,
    SelectInput,
    CytologyTextPreview,
    Modal,
    Loader,
    NumberInput
  },
  data() {
    return {
      enabled: false,
      protocolOptions: [],
      isAlternateOpen: false,
      isReviewing: false,
      isScreening: false,
      isPreviewOpen: false,
      forcedReview: CytReviewTypeEnum.None,
      isPromptOpen: false,
      isSystemMessageOpen: false,
      lastReview: null,
      lastScreening: null,
      currentPreviewResult: null,
      cytResults: [],
      currentResult: {
        cytResultTypeId: CytResultTypeEnum.Screener,
        resultedBy: "",
        resultedOn: new Date(),
        agreesWithPrevious: true,
        cytReviewTypeId: CytReviewTypeEnum.None,
        cytReviewTypeRequestedId: CytReviewTypeEnum.None,
        cytDiagnosticMacroId: null,
        macroIsModified: false,
        diagCodes: []
      },
      reviews: [],
      screenings: [],
      specimen: {},
      yesNoOptions: [
        {
          id: true,
          displayName: "Yes"
        },
        {
          id: false,
          displayName: "No"
        }
      ],
      reviewTypes: [
        {
          id: CytReviewTypeEnum.None,
          displayName: "None"
        },
        {
          id: CytReviewTypeEnum.Reviewer,
          displayName: "Reviewer"
        },
        {
          id: CytReviewTypeEnum.Pathologist,
          displayName: "Pathrologist"
        }
      ],
      initialType: null,
      macroDialogType: 0,
      isBuilderOpen: false,
      isMacroDialogOpen: false,
      spellcheckRan: false,
      icdOptions: [],
      prefillCodes: [],
      icdSearch: DropdownService.searchIcdCodes,
      cptSearch: DropdownService.searchCptCodes,
      mutationObserver: null,
      contactsSearch: ProviderAPI.searchContacts,
      pathologistSearch: DropdownService.searchPathologists,
      microscopic: "",
      caseStatuses: {},
      editTypes: getEditTypes(),
      originalSpecimen: {},

      intervalId: null,
      isTablePopupOpen: false,
      cptDisplay: "",
      shortkeys: getAltKeys("acdglmnoprsuwy")
    };
  },
  created() {
    DropdownService.getStatusEnum().then(res => {
      this.caseStatuses = Object.fromEntries(res.map(status => [status.id, status.displayName]));
    });

    this.originalSpecimen = cloneDeep(this.value);
    this.specimen = cloneDeep({
      ...this.value,
      initIcdCodes: this.value?.icdCodes ? this.value.icdCodes : [],
      initCptCodes: this.value?.cptCodes ? this.value.cptCodes : [],
      initResultsMacros: this.value?.resultsMacros ? this.value.resultsMacros : []
    });
    if (this.specimen.resultsMacros?.length && !this.specimen.macroIsModified) {
      this.firstMacroDiagnosticText = {
        diagnosis: this.specimen.diagnosis || null,
        microscopic: this.specimen.microscopic || null,
        specimenNotes: this.specimen.notes || null
      };
    }
    if (this.casePrefix) {
      this.labPrefix = this.casePrefix;
      DropdownService.getProtocol(this.labPrefix.id).then(res => {
        this.protocolOptions = sortCaseInsensitive(res);
      });
    }
    if (!this.hasCytologyPrompted) {
      this.initializePrompt();
    } else {
      this.handleLoadSession();
    }

    this.loadCytResults();
  },
  mounted() {
    //Always opens diagnosis by default.
    this.$nextTick(() => {
      this.setUserFocus();
    });

    eventBus.$on(ICD_ENGINE_START, () => this.toggleIcd(true));
    eventBus.$on(ICD_ENGINE_FINISH, () => this.toggleIcd(false));
    eventBus.$on(SAVE_FROM_CASE_HEADER, () => this.triggerShortkey({ srcKey: "s" }));
  },
  beforeDestroy() {
    if (this.mutationObserver) {
      this.mutationObserver?.disconnect();
    }
    eventBus.$off(ICD_ENGINE_START);
    eventBus.$off(ICD_ENGINE_FINISH);
    eventBus.$off(SAVE_FROM_CASE_HEADER);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      sessionDetails: state => state.sessionDetails,
      lastPathologist: state => state.sessionDetails.lastPathologist,
      textEditors: state => state.applicationSettings.textEditors || initialEditors,
      automaticMacroPopup: state =>
        state.applicationSettings.automaticMacroPopup !== null
          ? state.applicationSettings.automaticMacroPopup
          : state.labSettings.AutoMacroPopup,
      caseEditType: state => state.accessionStore.editType,
      caseDetails: state => state.accessionStore.caseDetails,
      casePrefix: state => state.accessionStore.casePrefix,
      caseHolds: state => state.accessionStore.caseHolds,
      specimens: state => state.accessionStore.specimens,
      SpellCheckOnSave: state => state.labSettings.SpellCheckOnSave,
      autoOpenEditors: state => state.applicationSettings.autoOpenEditors,
      dashboardMode: state => state.applicationSettings.accessionMode,
      confirmClearResultsMacro: state => state.applicationSettings.confirmClearResultsMacro,
      preserveMacroCaseNotes: state => state.applicationSettings.preserveMacroCaseNotes,
      ForceUpperCaseSite: state => state.labSettings.ForceUpperCaseSite,
      resultsMacroRetainScroll: state => state.applicationSettings.resultsMacroRetainScroll,
      autoOpenFirstEditorResults: state => state.applicationSettings.autoOpenFirstEditorResults,
      interfaceDiagnosisOptions: state => state.dropdowns.interfaceDiagnoses,
      hasCytologyPrompted: state => state.sessionDetails.cytologyPrompted,
      sessionResultDate: state => state.sessionDetails.resultedOnDate,
      isSessionRescreen: state => state.sessionDetails.reScreen
    }),
    ...mapGetters("accessionStore", ["isCaseEditable", "isReported", "primaryPathologist"]),
    ...mapGetters(["permissions", "webSpellcheckerLoaded", "cytReviewTypeId"]),
    isCytologist() {
      return Object.values(CytReviewTypeEnum).includes(this.cytologyReviewType);
    },
    reviewer() {
      if (this.lastReview) {
        return this.lastReview.resultedBy;
      }
      if (this.currentResult.cytReviewTypeId === CytReviewTypeEnum.Reviewer) {
        return this.username;
      }
      return "";
    },
    screener() {
      if (this.lastScreening) {
        return this.lastScreening.resultedBy;
      }
      if (this.currentResult.cytResultTypeId === CytResultTypeEnum.Screener) {
        return this.username;
      }
      return "";
    },
    currentViewUser() {
      if (this.currentViewType === "Screener") {
        return this.screener;
      }
      if (this.currentViewType === "Reviewer") {
        return this.reviewer;
      }
      return "";
    },
    currentViewType() {
      if (this.currentPreviewResult === null) {
        return null;
      }
      if (this.currentPreviewResult.cytResultTypeId === CytResultTypeEnum.Screener) {
        return "Screener";
      }
      if (this.currentPreviewResult.cytResultTypeId === CytResultTypeEnum.Reviewer) {
        return "Reviewer";
      }
      return null;
    },
    currentResultedOn() {
      if (this.currentPreviewResult === null) {
        return null;
      }
      if (this.currentPreviewResult.resultedOn) {
        return format(parseISO(this.currentPreviewResult.resultedOn), "M/dd/yyyy");
      }
      return "";
    },
    previewText() {
      if (this.currentPreviewResult === null) {
        return null;
      }
      if (this.currentPreviewResult.cytResultDiagnosisCodes.length > 0) {
        return this.currentPreviewResult.cytResultDiagnosisCodes;
      }
      return [];
    },
    reScreen: {
      get() {
        if (this.currentPreviewResult != null) {
          return this.currentPreviewResult?.cytResultTypeId === CytResultTypeEnum.Rescreening;
        }
        return this.isSessionRescreen;
      },
      set(val) {
        this.$store.commit("sessionDetails/setRescreening", val);
      }
    },
    resultDate: {
      get() {
        return this.sessionResultDate;
      },
      set(val) {
        this.$store.commit("sessionDetails/setResultedOnDate", val);
      }
    },
    disagree: {
      get() {
        return !this.currentResult.agreesWithPrevious;
      },
      set(val) {
        this.currentResult.agreesWithPrevious = !val;
      }
    },
    renderPreview() {
      if (this.currentResult?.diagCodes?.length > 0) {
        return true;
      }
      return false;
    },
    isScreener() {
      return this.cytReviewTypeId === CytReviewTypeEnum.Screener;
    },
    isReviewer() {
      return this.cytReviewTypeId === CytReviewTypeEnum.Reviewer;
    },
    isPathologist() {
      return this.cytReviewTypeId === CytReviewTypeEnum.Pathologist;
    },
    username() {
      return this.currentUser?.lastName + ", " + this.currentUser?.firstName;
    },
    cytologyReviewType() {
      return this.cytReviewTypeId;
    },
    cytologyReviewTypeDisplay() {
      const values = Object.keys(CytReviewTypeEnum).reduce((acc, key) => {
        acc[CytReviewTypeEnum[key]] = key;
        return acc;
      }, {});
      const val = values[this.cytologyReviewType];

      return val;
    },

    isReReporting() {
      return [
        CaseEditTypeEnum.Amended,
        CaseEditTypeEnum.Addendum,
        CaseEditTypeEnum.Corrected,
        CaseEditTypeEnum.NonDiagnostic,
        CaseEditTypeEnum.Other,
        CaseEditTypeEnum.Billing
      ].includes(this.caseEditType);
    },
    editorClasses() {
      return {
        upperCaseClinical: parseInt(this.labSettings.ForceUpperCaseClinical) || false,
        upperCaseSpecimenNote: parseInt(this.labSettings.ForceUpperCaseSpecimenNote) || false,
        upperCaseMicroscopic: parseInt(this.labSettings.ForceUpperCaseMicroscopic) || false,
        upperCaseCaseNote: parseInt(this.labSettings.ForceUpperCaseCaseNote) || false,
        upperCaseDiagnosis: parseInt(this.labSettings.ForceUpperCaseDiagnosis) || false,
        upperCaseGross: parseInt(this.labSettings.ForceUpperCaseGross) || false
      };
    },
    editType: {
      get() {
        return this.caseEditType;
      },
      set(value) {
        this.$store.commit("accessionStore/setEditType", value);
        return value;
      }
    },
    availableEditors() {
      const { CaseFieldEditDiagnosis, CaseFieldEditClinical } = this.permissions;
      let key = "";
      if (this.specimen && this.specimen?.id) {
        key = this.specimen.id;
      }
      const editors = sortBy(
        [
          CaseFieldEditDiagnosis && { ...this.textEditors.diagnosis, name: "diagnosis" },
          CaseFieldEditClinical && { name: "clinical", ...this.textEditors.clinical }
        ],
        "resultsOrder"
      );
      editors.forEach(e => {
        return e ? (e.key = `${e?.name}-${key}`) : e;
      });
      return editors.filter(value => value);
    },
    reasonForChange: {
      get() {
        return this.$store.state.accessionStore.reasonForChange;
      },
      set(value) {
        this.$store.commit("accessionStore/setReasonForChange", value);
        return value;
      }
    },
    formDisabled() {
      if (!this.isCytologist) {
        return true;
      }
      if (this.isReviewing) {
        return false;
      }
      if (this.isScreening) {
        return false;
      }
      return true;
    },
    caseMacroHolds() {
      return this.caseHolds.filter(hold => hold.text && hold.text.includes("Added by Macro"));
    },

    billingCPTWarning() {
      return parseInt(this.labSettings.BillingCPTWarning) || false;
    },
    resultsHeaderClass() {
      if (/\(M\)/i.test(this.macroHeader)) {
        return { "text-danger": true };
      }
      return { "text-danger": false };
    },
    draggableEditors: {
      get() {
        return this.availableEditors;
      },
      set(value) {
        const newEditors = value
          .map((editor, idx) => {
            return {
              ...editor,
              resultsOrder: idx
            };
          })
          .reduce((acc, curr) => {
            acc[curr.name] = curr;
            return acc;
          }, {});
        this.$store.commit("applicationSettings/setEditorsOrder", {
          ...this.textEditors,
          ...newEditors
        });
      }
    },
    macroHeader() {
      return `<u>R</u>es<u>u</u>lts Macros${
        this.specimen.macroIsModified ? " <span class='text-danger'>(modified)</span>" : ""
      }`;
    },
    upperCaseSite() {
      if (Number(this.ForceUpperCaseSite)) {
        return true;
      }
      return false;
    }
  },
  validations() {
    return {
      specimen: {
        pathologists: {
          required
        },
        icdCodes: {
          required: vm => {
            return !this.labSettings.SpecimenICDOptional && this.casePrefix.isBillingEnabled
              ? helpers.req(vm)
              : true;
          }
        }
      }
    };
  },
  watch: {
    value: {
      handler(nv, ov) {
        if (ov && ov?.id !== nv?.id) {
          this.$nextTick(() => {
            return this.setUserFocus();
          });
        }

        this.specimen = cloneDeep({
          ...nv,
          initIcdCodes: nv?.icdCodes ? nv.icdCodes : [],
          initCptCodes: nv?.cptCodes ? nv.cptCodes : [],
          initResultsMacros: nv?.resultsMacros ? nv.resultsMacros : []
        });
      }
    },
    "specimen.pathologists": {
      deep: true,
      handler(value) {
        const anyPrimary = value.filter(e => e.isPrimary);
        if ((!anyPrimary.length && value.length) || anyPrimary.length > 1) {
          this.setPrimaryPathologist(value[0].id);
        }
      }
    }
  },
  methods: {
    toggleAtlernate() {
      this.isAlternateOpen = !this.isAlternateOpen;
    },
    handleSelectCodes(data) {
      this.currentResult.diagCodes = data.diagCodes;
      this.currentResult.cytDiagnosticMacroId = data.cytDiagnosticMacroId;
      this.currentResult.macroIsModified = data.macroIsModified;
    },
    handleCancelReview() {
      this.isReviewing = false;
      this.isScreening = false;
      this.isPreviewOpen = true;
      this.currentPreviewResult = this.lastScreening;
    },

    handleAddReview() {
      this.currentResult.cytResultTypeId = CytResultTypeEnum.Reviewer;
      this.currentResult.cytReviewTypeId = CytReviewTypeEnum.Reviewer;
      // Copy the screening diag codes
      const diagCodes = this.lastScreening.cytResultDiagnosisCodes.map(e => {
        return {
          cytDiagnosticCodeId: e.cytDiagnosticCodeId,
          comment: e.comment
        };
      });
      this.prefillCodes = diagCodes;
      this.isReviewing = true;
      this.isScreening = false;
      this.isPreviewOpen = false;
    },
    handleChangePreview() {
      const isLastScreening =
        this.currentPreviewResult?.cytResultTypeId === this.lastScreening?.cytResultTypeId;
      if (isLastScreening) {
        this.currentPreviewResult = this.lastReview;
      } else {
        // Find next review
        const currentIdx = this.reviews.findIndex(e => e.id === this.currentPreviewResult.id);
        const nextIsEnd = currentIdx === this.reviews.length - 1;
        if (nextIsEnd) {
          this.currentPreviewResult = this.lastScreening;
        } else if (currentIdx === -1) {
          this.currentPreviewResult = this.lastReview;
        } else {
          const nextTarget = this.reviews[currentIdx + 1];
          const currentUserName = this.currentPreviewResult.resultedBy;
          if (currentUserName === this.username) {
            this.isReviewing = true;
            this.isScreening = false;
            this.isPreviewOpen = false;
            this.currentResult = nextTarget;
            this.currentPreviewResult = null;
          } else {
            this.isReviewing = false;
            this.currentPreviewResult = nextTarget;
            this.isPreviewOpen = true;
          }
        }
      }
    },
    handleLoadSession() {
      this.currentResult.resultedBy = this.username;
      this.currentResult.resultedOn = this.resultDate;
      if (this.reScreen) {
        this.currentResult.cytResultTypeId = CytResultTypeEnum.Rescreening;
      }
      if (this.isPromptOpen) {
        this.$store.commit("sessionDetails/setCytologyPrompted", true);
        this.isPromptOpen = false;
      }
    },
    handleMesage(response) {
      // 1-4
      switch (response) {
        case 1: {
          // Re-Screen
          this.enabled = true;
          this.currentResult.cytResultTypeId = CytResultTypeEnum.Screener;
          if (this.isPathologist) {
            this.currentResult.cytReviewTypeId = CytReviewTypeEnum.Pathologist;
          } else if (this.isScreener) {
            this.currentResult.cytReviewTypeId = CytReviewTypeEnum.Screener;
          }
          break;
        }
        case 2: {
          // Review
          this.enabled = true;
          break;
        }
        default: {
          this.enabled = false;
          break;
        }
      }
      this.isSystemMessageOpen = false;
    },
    togglePrompt() {
      this.isPromptOpen = !this.isPromptOpen;
    },
    async loadCytResults() {
      const store = cytologyResultService.createSearch();
      // Load Screenings
      const screenings = store.load({
        filter: [
          ["cytResultTypeId", CytResultTypeEnum.Screener],
          "and",
          ["specimenId", this.specimen.id]
        ]
      });
      const pathologistResults = store.load({
        filter: [
          ["cytResultTypeId", CytResultTypeEnum.Pathologist],
          "and",
          ["specimenId", this.specimen.id]
        ]
      });

      // Load the latest screening
      const latestScreening = store.load({
        filter: [
          ["cytResultTypeId", CytResultTypeEnum.Screener],
          "and",
          ["specimenId", this.specimen.id]
        ],
        sort: [{ selector: "resultedOn", desc: true }],
        take: 1
      });
      // Load Reviews
      const reviews = store.load({
        filter: [
          ["cytResultTypeId", CytResultTypeEnum.Reviewer],
          "and",
          ["specimenId", this.specimen.id]
        ]
      });
      const latestReview = store.load({
        filter: [
          ["cytResultTypeId", CytResultTypeEnum.Reviewer],
          "and",
          ["specimenId", this.specimen.id]
        ],
        sort: [{ selector: "resultedOn", desc: true }],
        take: 1
      });
      const [screeningsData, reviewsData, latestScreeningData, latestReviewData] =
        await Promise.all([screenings, reviews, latestScreening, latestReview, pathologistResults]);

      this.screenings = screeningsData;
      this.reviews = reviewsData;

      if (latestScreeningData.length) {
        const latestScreen = latestScreeningData[0];
        if (latestScreen.id) {
          await cytologyResultService.getOne(latestScreen.id).then(data => {
            this.lastScreening = data;
            this.currentPreviewResult = data;
            this.isPreviewOpen = true;
          });
        }
      } else {
        this.lastScreening = null;
      }
      if (latestReviewData.length) {
        const { id } = latestReviewData[0];
        await cytologyResultService.getOne(id).then(data => {
          this.lastReview = data;
        });
      } else {
        this.lastReview = null;
      }

      if (!this.isCytologist) {
        this.enabled = false;
        return;
      }
      if (this.screenings?.length < 1) {
        this.currentResult.cytResultTypeId = CytResultTypeEnum.Screener;
        this.currentResult.cytReviewTypeId = CytReviewTypeEnum.None;
        this.isScreening = true;
      } else {
        this.currentResult.cytResultTypeId = CytResultTypeEnum.Reviewer;
        if (this.isPathologist) {
          //Pathologist review
          this.currentResult.cytReviewTypeId = CytReviewTypeEnum.Pathologist;
        }
        const lastReviewUser = this.lastReview?.resultedBy;
        if (lastReviewUser === this.username) {
          this.currentResult = this.lastReview;
          this.isReviewing = true;
          this.isScreening = false;
          this.isPreviewOpen = false;
        } else {
          this.isSystemMessageOpen = true;
        }
      }

      return [screeningsData, reviewsData];
    },
    initializePrompt(type) {
      this.initialType = type;
      this.togglePrompt();
    },
    async handleMacro(data) {
      const icdSet = new Set(data.diagnosisIcds);
      const icds = await Promise.all(Array.from(icdSet).map(e => this.icdSearch.byKey(e)));
      this.specimen.icdCodes = icds;
      this.currentResult = {
        ...this.currentResult,
        diagCodes: data.diagCodes,
        cytDiagnosticMacroId: data.cytDiagnosticMacroId,
        macroIsModified: data.macroIsModified
      };
    },
    triggerShortkey(event, isManual) {
      if (isModalOpen() && !isManual) {
        return;
      }
      if (event.srcKey !== "o") {
        this.closeIcdIfOpen();
      }
      switch (event.srcKey) {
        case "p":
          if (!this.$refs.provider.isFocused) {
            this.$refs.provider.focus();
          } else {
            this.$refs.pathologists.focus();
          }
          break;
        case "r":
          if (!this.isMacroDialogOpen) {
            scrollToElement(this.$refs.resultsMacro.$el);
          }
          this.macroDialogType = MacroTypeEnum.Results;
          this.isMacroDialogOpen = !this.isMacroDialogOpen;
          break;
        case "o":
          if (!this.$refs.icd.status) {
            scrollToElement(this.$refs.icd.$el);
          }
          this.$nextTick(this.$refs.icd.toggleDropdown());
          break;
        case "u":
          this.$refs.resultsMacro.focusMacro();
          scrollToElement(this.$refs.resultsMacro.$el);
          break;
        case "w":
          this.handleClearMacroShortkey();
          break;
        case "s":
          if (!this.formDisabled) {
            this.$store.commit("accessionStore/setLoading", true);
            this.saveResults();
          }
          break;
        case "l":
          // Removed table logic - key is available
          // this.toggleTablePopup();
          break;
        default:
          {
            const targetEditorName = this.availableEditors.find(
              e => e.accessKey === event.srcKey
            ).name;
            this.$refs[targetEditorName][0].focus(true);
          }
          break;
      }
    },
    openEditors() {
      this.triggerShortkey({ srcKey: "d" }, true);
    },
    htmlHasText(htmlString) {
      if (htmlString) {
        return !!getTextFromHtml(htmlString).trim();
      }
      return false;
    },
    async checkEditorSpelling() {
      const spellcheck$ = fromBusEvent(SPELL_CHECK_ACTION).pipe(
        filter(({ data }) => data.type === "spelling"),
        switchMap(({ instance }) => {
          return interval(500).pipe(map(() => instance.getProblemsCount()));
        })
      );
      const closeDialog$ = interval(500).pipe(
        map(() => {
          const dialogElement = document.querySelector(
            "div.wsc-dialog.wsc-theme-custom.wsc-element.wsc--border-box:not(.wsc--hidden)"
          );
          return dialogElement;
        }),
        filter(e => e != null),
        exhaustMap(wscDialogEl => elementObserver(wscDialogEl)),
        map(([mutations]) => {
          if (Array.isArray(mutations)) {
            for (const mutation of mutations) {
              const { target } = mutation;
              if (target.classList.contains("wsc--hidden")) {
                return 0;
              }
            }
          }
          return 1;
        })
      );
      if (typeof window.WEBSPELLCHECKER !== "undefined" && window.WEBSPELLCHECKER?.init) {
        const spellCheckers = window.WEBSPELLCHECKER.getInstances();
        for (const spellChecker of spellCheckers) {
          if (spellChecker.getProblemsCount && spellChecker.getProblemsCount()) {
            const instanceNode = spellChecker.getContainerNode();
            let editorRef = this.$refs[instanceNode.id];
            if (Array.isArray(editorRef)) {
              editorRef = editorRef[0];
              if (editorRef.expand) {
                editorRef.expand();
              }
            }
            await this.$nextTick();
            scrollToElement(instanceNode);

            spellChecker.openDialog();
            await merge(closeDialog$, spellcheck$)
              .pipe(takeWhile(val => val > 0))
              .toPromise();
            if (editorRef.collapse) {
              editorRef.collapse();
              await this.$nextTick();
            }
          }
        }
        const dialog = document.querySelector(
          ".wsc-dialog.wsc-element.wsc--border-box:not(.wsc--hidden)"
        );
        if (dialog !== null) {
          dialog.classList.toggle("wsc--hidden");
        }
      }
    },
    async saveResults() {
      const specimen = this.specimen;
      this.$store.dispatch("logTimeSinceBase", "save function triggered");
      //Validations
      // Must have atlead 1 adequacy & 1 Diagnosis
      const { diagCodes, cytReviewTypeId } = this.currentResult;
      if (diagCodes.length) {
        this.$store.commit("accessionStore/setLoading", false);
        let hasDiagnosis = false;
        let hasAdequacy = false;

        for (const cd of diagCodes) {
          const details = await cytologyEntityService.getSingleEntity(
            CytologyEntities.CytDiagnosticCodes,
            cd.cytDiagnosticCodeId
          );
          if (details?.cytCodeGroupId === CytCodeGroupEnum.Adequacy) {
            hasAdequacy = true;
          }
          if (details?.cytCodeGroupId === CytCodeGroupEnum.Diagnosis) {
            hasDiagnosis = true;
          }
        }
        if (!hasDiagnosis || !hasAdequacy) {
          this.$store.commit("accessionStore/setLoading", false);
          alert("Must have at least 1 adequacy and 1 diagnosis.");
          return;
        }
      }
      if (cytReviewTypeId === CytReviewTypeEnum.Reviewer) {
        const agrees = await confirm("Do you agree with the previous results?", "Agree");
        this.currentResult.agreesWithPrevious = agrees;
      }

      const newIcdCodes = specimen.icdCodes?.filter(
        e => specimen.initIcdCodes?.findIndex(val => val.id === e.id) === -1
      );
      const deletedIcdCodes = specimen.initIcdCodes?.filter(
        e => specimen.icdCodes?.findIndex(val => val.id === e.id) === -1
      );
      const updatedIcdCodes = specimen.initIcdCodes
        ?.map(val => {
          if (deletedIcdCodes?.findIndex(elem => elem.id === val.id) >= 0) {
            return { ...val, isDeleted: true };
          } else {
            return { ...val, isDeleted: false };
          }
        })
        .concat(newIcdCodes);

      unset(specimen, "initIcdCodes");
      unset(specimen, "initCptCodes");
      unset(specimen, "initResultsMacros");
      for (const editor of this.draggableEditors) {
        this.specimen[editor.name] = removeExtraDivs(this.specimen[editor.name]);
      }
      specimen.contact = fixProviderPayload(specimen.contact);
      return this.$emit("save", {
        ...specimen,
        icdCodes: updatedIcdCodes,
        cytResults: this.currentResult
      });
    },
    async setUserFocus() {
      const shortkeyToTrigger = this.autoOpenFirstEditorResults
        ? this.availableEditors[0].accessKey
        : "d";
      if (!this.primaryPathologist?.id) {
        if (this.lastPathologist?.id) {
          this.specimen.pathologists.push({
            ...this.lastPathologist,
            isPrimary: true,
            lastUsed: true
          });
        } else {
          this.$refs.pathologists?.focus();
          return this.$watchAsObservable("specimen.pathologists")
            .pipe(
              filter(({ newValue, oldValue }) => {
                if (!this.automaticMacroPopup) {
                  return false;
                }
                if (newValue.length && !oldValue.length) {
                  return !this.specimen.diagnosis?.length;
                }
                return false;
              }),
              take(1)
            )
            .toPromise()
            .then(() => {
              if (this.automaticMacroPopup) {
                this.macroDialogType = 0;
                this.isMacroDialogOpen = true;
              } else if (this.resultsMacroRetainScroll) {
                scrollToElement(this.$refs.header);
              } else {
                this.triggerShortkey({ srcKey: shortkeyToTrigger }, true);
              }
            });
        }
      }
      scrollToElement(this.$refs.header);
    },

    setPrimaryPathologist(id) {
      this.specimen.pathologists = this.specimen.pathologists.map(entry => {
        if (entry?.id === id) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
    },
    closeIcdIfOpen() {
      if (this.$refs.icd.status) {
        this.$refs.icd.toggleDropdown();
      }
    },
    removeICD(id) {
      this.specimen.icdCodes = this.specimen.icdCodes.filter(e => e.id !== id);
      this.specimen.isICDModified = true;
    },

    toggleIcd(status) {
      this.isIcdRunning = status;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-row {
  row-gap: 1rem;
  margin-bottom: 0.5rem;
}

.text-preview {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  margin-bottom: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
}

.gap-x-4 {
  column-gap: 1rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 0.25rem;
}

.result_macro {
  background-color: $primary;
}
.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: $primary;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}
.tag-btn {
  padding: 0px;
  margin: 0px;
}
.bg-gray {
  background-color: $gray;
}
@media screen and (min-width: 768px) {
  .confirm-dialog {
    width: 500px;
  }
}

.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: $primary;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
}

#editors {
  max-width: 100%;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
