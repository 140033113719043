var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"builder-container"},[_c('div',{staticClass:"row align-items-center"},[_c('select-input',{staticClass:"col",attrs:{"label":"Result Macro","name":"targetMacro","dataSource":_vm.cytologyMacroSource},model:{value:(_vm.cytMacroId),callback:function ($$v) {_vm.cytMacroId=$$v},expression:"cytMacroId"}}),_c('button',{staticClass:"btn btn-secondary mx-2 mt-3",on:{"click":_vm.handleClear}},[_vm._v("Clear")])],1),_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Types")]),_vm._l((_vm.mainTypes),function(item){return _c('button',{key:item.id,staticClass:"btn d-flex align-items-center cursor-pointer w-100",class:{
          'btn-dark': _vm.selectedTypeKey.includes(item.id),
          'btn-light': !_vm.selectedTypeKey.includes(item.id)
        },attrs:{"data-id":item.id},on:{"click":_vm.handleSelectType}},[_c('h3',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),(_vm.hasSelectionType(item))?_c('IconButton',{staticClass:"btn btn-link d-inline-flex p-1 ml-auto justify-between align-items-center ml-2",attrs:{"icon":"plus"},on:{"click":function($event){$event.stopPropagation();return _vm.handleComment(item)}}},[_c('span',{staticClass:"ml-2"},[_vm._v(" Text ")])]):_vm._e()],1)})],2),_c('div',{staticClass:"col"},[_vm._m(0),_c('DxList',{staticClass:"list",attrs:{"data-source":_vm.codesSource,"search-enabled":true,"selected-item-keys":_vm.selectedCodeKeys,"selection-mode":_vm.selectionMode},on:{"selection-changed":_vm.listSelectionChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.data;
return [_c('button',{staticClass:"diagnosis-code btn d-flex align-items-center w-100",attrs:{"data-id":item.id}},[_c('h1',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]}}])})],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Modifiers")]),_c('div',[_c('DxList',{staticClass:"list",attrs:{"search-enabled":true,"selected-item-keys":_vm.selectedModifierKeys,"selection-mode":_vm.selectionMode,"noDataText":_vm.selectedCodes ? 'No Modifiers' : 'Select a code to see modifiers',"dataSource":_vm.modifiersSource},on:{"selection-changed":_vm.modifierSelectionChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)])])]),(_vm.selectedCodesAndMods)?_c('div',{staticClass:"col"},[_c('CytologyTextPreview',{attrs:{"selectedCodes":_vm.selectedCodesAndMods}})],1):_vm._e(),_c('Modal',{attrs:{"status":_vm.isCommenterOpen},on:{"close":function($event){_vm.isCommenterOpen = false}}},[_c('form',{staticClass:"d-flex flex-column p-4",on:{"submit":function($event){$event.preventDefault();return _vm.submitComment.apply(null, arguments)}}},[_c('GeneralMacroEnabledEditor',{attrs:{"label":"Comment","name":"Comment","autoFocus":"","isExpanded":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('button',{staticClass:"btn btn-primary ml-auto mt-8"},[_vm._v("Save")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center py-2"},[_c('h3',[_vm._v("Codes")])])}]

export { render, staticRenderFns }