<template>
  <div class="builder-container" ref="container">
    <div class="row align-items-center">
      <select-input
        class="col"
        label="Result Macro"
        name="targetMacro"
        v-model="cytMacroId"
        :dataSource="cytologyMacroSource"
      ></select-input>
      <button class="btn btn-secondary mx-2 mt-3" @click="handleClear">Clear</button>
    </div>
    <div class="row flex-wrap">
      <div class="col">
        <h3>Types</h3>
        <button
          v-for="item in mainTypes"
          :key="item.id"
          :data-id="item.id"
          @click="handleSelectType"
          class="btn d-flex align-items-center cursor-pointer w-100"
          :class="{
            'btn-dark': selectedTypeKey.includes(item.id),
            'btn-light': !selectedTypeKey.includes(item.id)
          }"
        >
          <h3 class="name">{{ item.name }}</h3>
          <IconButton
            v-if="hasSelectionType(item)"
            icon="plus"
            @click.stop="handleComment(item)"
            class="btn btn-link d-inline-flex p-1 ml-auto justify-between align-items-center ml-2"
          >
            <span class="ml-2"> Text </span>
          </IconButton>
        </button>
      </div>
      <div class="col">
        <div class="d-flex align-items-center py-2">
          <h3>Codes</h3>
        </div>
        <DxList
          :data-source="codesSource"
          :search-enabled="true"
          :selected-item-keys="selectedCodeKeys"
          class="list"
          :selection-mode="selectionMode"
          @selection-changed="listSelectionChanged"
        >
          <template #item="{ data: item }">
            <button class="diagnosis-code btn d-flex align-items-center w-100" :data-id="item.id">
              <h1 class="name">{{ item.name }}</h1>
            </button>
          </template>
        </DxList>
      </div>
      <div class="col">
        <div class="col">
          <h3>Modifiers</h3>
          <div>
            <DxList
              :search-enabled="true"
              :selected-item-keys="selectedModifierKeys"
              class="list"
              :selection-mode="selectionMode"
              :noDataText="selectedCodes ? 'No Modifiers' : 'Select a code to see modifiers'"
              :dataSource="modifiersSource"
              @selection-changed="modifierSelectionChanged"
            >
              <template #item="{ data: item }">
                <div>
                  {{ item.name }}
                </div>
              </template>
            </DxList>
          </div>
        </div>
      </div>
    </div>
    <div class="col" v-if="selectedCodesAndMods">
      <CytologyTextPreview :selectedCodes="selectedCodesAndMods" />
    </div>

    <Modal :status="isCommenterOpen" @close="isCommenterOpen = false">
      <form @submit.prevent="submitComment" class="d-flex flex-column p-4">
        <GeneralMacroEnabledEditor
          v-model="comment"
          label="Comment"
          name="Comment"
          autoFocus
          isExpanded
        />
        <button class="btn btn-primary ml-auto mt-8">Save</button>
      </form>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SelectInput from "../common/SelectInput.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { DxList } from "devextreme-vue";
import DataSource from "devextreme/data/data_source";
import { CytCodeGroupEnum, CytologyModifierMap } from "@/modules/enums";
import { getTextFromHtml } from "@/modules/helpers";
import CytologyTextPreview from "../CytologyTextPreview.vue";
import Modal from "../common/Modal.vue";
import GeneralMacroEnabledEditor from "../common/GeneralMacroEnabledEditor.vue";
import IconButton from "../common/IconButton.vue";
import { debounceTime, switchMap } from "rxjs/operators";

export default {
  name: "CytologyResultsBuilder",
  props: {
    specimenId: {
      type: Number
    },
    resultedOn: {
      type: Date
    },
    startingValues: {
      type: Array
    }
  },
  components: {
    SelectInput,
    IconButton,
    DxList,
    Modal,
    CytologyTextPreview,
    GeneralMacroEnabledEditor
  },
  data() {
    return {
      scrollPos: 0,
      selectedTypeKey: [],
      commentTargetId: null,
      comment: "",
      isCommenterOpen: false,
      isLoading: false,
      result: {
        cytDiagnosticMacroId: null,
        macroIsModified: false,
        diagCodes: []
      },
      cytMacroId: null,
      selectedTypes: [],
      selectionMode: "multiple",
      selectedCodes: [],
      selectedModifiers: [],
      columns: [
        {
          dataField: "name",
          caption: "Name"
        },
        {
          dataField: "formattedText",
          caption: "Text",
          calculateCellValue: data => data.formattedText && getTextFromHtml(data.formattedText),
          minWidth: "250px",
          width: "40%",
          cssClass: "truncate"
        }
      ]
    };
  },
  mounted() {
    if (this.startingValues?.length) {
      this.handleLoadPrefill();
    }
  },
  domStreams: ["$changedState"],
  subscriptions() {
    const $debounceAndCancel = this.$changedState.pipe(
      debounceTime(500),
      switchMap(val => this.saveCytologyResults(val))
    );
    return {
      $debounceAndCancel
    };
  },
  methods: {
    async handleLoadPrefill() {
      for (const item of this.startingValues) {
        const diagCode = await cytologyService.getSingleEntity(
          CytologyEntities.CytDiagnosticCodes,
          item.cytDiagnosticCodeId
        );
        if (diagCode) {
          this.selectedCodes.push({
            ...diagCode,
            comment: item.comment
          });
        }
      }
    },
    handleClear() {
      this.selectedTypeKey = [];
      this.selectedTypes = [];
      this.selectedCodes = [];
      this.selectedModifiers = [];
      this.cytMacroId = null;
    },
    hasSelection() {
      return this.selectedCodes.length > 0;
    },
    handleSelectType(event) {
      const { id } = event.currentTarget.dataset;
      this.selectedType = [id];
      this.selectedTypeKey = [id];
    },
    listSelectionChanged(e) {
      const { addedItems, removedItems } = e;
      const removedKeys = removedItems.map(e => e.id);
      let currentItems = [...this.selectedCodes];
      const currentItemKeys = new Set(currentItems.map(e => e.id));
      let hasModified = false;
      for (const item of currentItems) {
        if (removedKeys.includes(item.id)) {
          currentItems = currentItems.filter(e => e.id !== item.id);
        }
      }
      if (addedItems.length) {
        addedItems.forEach(e => {
          if (!currentItemKeys.has(e.id)) {
            currentItems.push(e);
            hasModified = true;
          }
        });
      }
      if (removedItems.length > 0) {
        hasModified = true;
      }
      if (currentItems.length > 0) {
        this.selectedCodes = currentItems;
        if (this.cytMacroId) {
          this.$changedState.next(hasModified);
        } else {
          this.$changedState.next(false);
        }
      } else {
        this.selectedCodes = [];
        this.$changedState.next(false);
      }
    },
    hasSelectionType({ id }) {
      const selectedType = this.selectedCodes.findIndex(e => e.cytCodeGroupId === id);
      return selectedType > -1;
    },
    handleComment(item) {
      const selectedType = this.selectedCodes.findIndex(e => e.cytCodeGroupId === item.id);
      if (selectedType > -1) {
        const selectedCode = this.selectedCodes[selectedType];
        this.commentTargetId = item.id;
        this.comment = selectedCode?.comment || "";
      }
      //Save the scroll pos of the container ref;

      this.isCommenterOpen = true;
    },
    submitComment() {
      const targetId = this.commentTargetId;
      console.log(this.selectedCodes);
      const targetIdx = this.selectedCodes.findIndex(c => {
        return c.cytCodeGroupId === targetId;
      });
      if (targetIdx > -1) {
        const record = this.selectedCodes[targetIdx];
        this.selectedCodes = [
          ...this.selectedCodes.slice(0, targetIdx),
          { ...record, comment: this.comment },
          ...this.selectedCodes.slice(targetIdx + 1)
        ];
      }
      this.comment = "";
      this.commentTargetId = null;
      this.isCommenterOpen = false;
    },
    modifierSelectionChanged(e) {
      const { addedItems, removedItems } = e;
      const removedKeys = removedItems.map(e => e.id);
      let currentItems = [...this.selectedModifiers];
      const currentItemKeys = new Set(currentItems.map(e => e.id));
      let hasModified = false;
      for (const item of currentItems) {
        if (removedKeys.includes(item.id)) {
          currentItems = currentItems.filter(e => e.id !== item.id);
        }
      }
      if (addedItems.length) {
        addedItems.forEach(e => {
          if (!currentItemKeys.has(e.id)) {
            currentItems.push(e);
            hasModified = true;
          }
        });
      }
      if (removedItems.length > 0) {
        hasModified = true;
      }
      if (currentItems.length > 0) {
        this.selectedModifiers = currentItems;
        if (this.cytMacroId) {
          this.$changedState.next(hasModified);
        } else {
          this.$changedState.next(false);
        }
      } else {
        this.selectedModifiers = [];
        this.$changedState.next(false);
      }
    },
    async saveCytologyResults(isModified) {
      try {
        this.isLoading = false;
        const diagCodeIds = [];
        const diagnosisIcds = [];
        //Iterate as it is rendered to ensure it saves with same order.

        const targetCodes = await Promise.all(
          this.selectedCodes.map(async e => {
            const details = await cytologyService.getSingleEntity(
              CytologyEntities.CytDiagnosticCodes,
              e.id
            );
            return {
              ...details,
              comment: e.comment
            };
          })
        );

        for (const targetCode of targetCodes) {
          diagnosisIcds.push(...targetCode.icdCodes);
          diagCodeIds.push({
            cytDiagnosticCodeId: targetCode.id,
            comment: targetCode.comment
          });
        }
        for (const targetCode of this.selectedModifiers) {
          diagCodeIds.push({
            cytDiagnosticCodeId: targetCode.id,
            comment: targetCode.comment
          });
        }
        // Generate the appropriate html content
        this.$emit("submit", {
          diagnosisIcds,
          cytDiagnosticMacroId: this.cytMacroId,
          macroIsModified: isModified,
          diagCodes: diagCodeIds
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSelectMacro(id) {
      const macro = await cytologyService.getSingleEntity(CytologyEntities.CytDiagnosticMacros, id);
      if (macro) {
        const { codes } = macro;
        const codeDetails = await Promise.all(
          codes
            //Sort by sequence number
            .sort((a, b) => a.seqNum - b.seqNum)
            .map(e =>
              cytologyService
                .getSingleEntity(CytologyEntities.CytDiagnosticCodes, e.cytDiagnosticCodeId)
                .then(res => {
                  return {
                    ...res,
                    seqNum: e.seqNum
                  };
                })
            )
        );
        const diagCodes = codeDetails.sort((a, b) => a.seqNum - b.seqNum);
        const selectedCodeKeys = new Set();
        const selectedModifierKeys = new Set();
        for (const code of diagCodes) {
          const { cytCodeGroupId } = code;
          const isTopGroup = this.topCodes.includes(cytCodeGroupId);
          if (isTopGroup) {
            if (!selectedCodeKeys.has(code.cytDiagnosticCodeId) && selectedCodeKeys.size < 1) {
              selectedCodeKeys.add(code.id);
            }
          } else {
            if (!selectedModifierKeys.has(code.id) && selectedModifierKeys.size < 1) {
              selectedModifierKeys.add(code.id);
            }
          }
        }
        this.selectedCodes = diagCodes;
        this.$changedState.next(false);
      }
    }
  },
  watch: {
    cytMacroId(nv, ov) {
      if (nv && nv !== ov) {
        this.result.cytDiagnosticMacroId = nv;
        this.handleSelectMacro(nv);
      }
    }
  },
  computed: {
    ...mapState({
      prefix: state => state.accessionStore.casePrefix,
      adequacyTerm: state => state.labSettings.CytologyTermAdequacy,
      commentTerm: state => state.labSettings.CytologyTermComment,
      diagnosisTerm: state => state.labSettings.CytologyTermDiagnosis,
      recommendationTerm: state => state.labSettings.CytologyTermRecommendation,
      hormonalTerm: state => state.labSettings.CytologyTermHormonal
    }),
    selectedCodeKeys: {
      get() {
        return this.selectedCodes.map(e => e.id);
      }
    },
    selectedModifierKeys: {
      get() {
        return this.selectedModifiers.map(e => e.id);
      }
    },
    ...mapGetters(["cytReviewType"]),
    cytologyCodes() {
      return cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes);
    },

    cytologyMacroSource() {
      return cytologyService.createSearch(CytologyEntities.CytDiagnosticMacros);
    },

    cytTypeId() {
      return this.prefix.cytTypeId;
    },
    typeMap() {
      return Object.keys(CytCodeGroupEnum).reduce((acc, key) => {
        acc[CytCodeGroupEnum[key]] = key;
        return acc;
      }, {});
    },
    topCodes() {
      return [
        CytCodeGroupEnum.Adequacy,
        CytCodeGroupEnum.Diagnosis,
        CytCodeGroupEnum.Comment,
        CytCodeGroupEnum.GeneralCategorization,
        CytCodeGroupEnum.Hormonal,
        CytCodeGroupEnum.Recommendation
      ];
    },
    selectedCodesAndMods() {
      console.log("Render");
      if (!this.selectedCodes.length && !this.selectedModifiers.length) {
        return null;
      }
      return [...this.selectedCodes, ...this.selectedModifiers];
    },

    mainTypes() {
      return [
        {
          id: CytCodeGroupEnum.Adequacy,
          name: this.adequacyTerm || "Adequacy"
        },
        {
          id: CytCodeGroupEnum.Diagnosis,
          name: this.diagnosisTerm || "Diagnosis"
        },
        {
          id: CytCodeGroupEnum.Recommendation,
          name: this.reccomendationTerm || "Recommendation"
        },
        {
          id: CytCodeGroupEnum.Hormonal,
          name: this.hormonalTerm || "Hormonal"
        },
        {
          id: CytCodeGroupEnum.Comment,
          name: this.commentTerm || "Comment"
        }
      ];
    },
    codesSource() {
      if (!this.selectedTypeKey?.length) {
        return [];
      }
      const groupFilter = this.selectedTypeKey.map(e => ["cytCodeGroupId", e]);
      return new DataSource({
        key: "id",
        store: this.cytologyCodes,
        searchExpr: ["name", "code"],
        sort: [
          { selector: "cytCodeGroupId", desc: false },
          { selector: "severityLevel", desc: true }
        ],
        filter: [
          groupFilter.reduce((acc, curr) => {
            if (acc.length) {
              acc.push("or");
            }
            acc.push(curr);
            return acc;
          }, []),
          "and",
          ["cytTypeId", this.cytTypeId]
        ]
      });
    },
    modifiersSource() {
      if (!this.selectedTypeKey?.length) {
        return [];
      }
      const modifiers = this.selectedTypeKey.map(e => CytologyModifierMap[e]).filter(e => e);
      const filter = [
        ["cytTypeId", "=", this.cytTypeId],
        "and",
        modifiers
          .map(code => ["cytCodeGroupId", "=", code])
          .reduce((acc, val) => {
            if (acc.length) {
              acc.push("or");
            }
            acc.push(val);
            return acc;
          }, [])
      ];

      return new DataSource({
        key: "id",
        store: this.cytologyCodes,
        searchExpr: ["name", "code"],
        sort: [
          { selector: "cytCodeGroupId", desc: false },
          { selector: "severityLevel", desc: true }
        ],
        filter
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.builder-container {
  color: hsl(0, 0%, 10%);
}
::v-deep .diagnosis-code {
  padding: 0;
}
.name {
  font-size: 1.15rem;
  font-weight: bold;
}
.list {
  max-height: 300px;
  overflow-y: auto;
}

dl {
  display: flex;
  color: hsl(0, 0%, 40%);
  column-gap: 0.5rem;
}

dt {
  font-weight: 400;
  margin-bottom: 0.25rem;
}
</style>
