<template>
  <div v-if="selectedCodes" class="text-preview" ref="textContainer">
    <template v-for="group of stringElements">
      <div :key="group.key">
        <div v-if="group.header" class="d-inline-flex align-groups-start">
          <h3>**{{ group.header }}**</h3>
        </div>
        <div v-for="item in group.items" :key="item.key" class="d-flex">
          <div v-if="item.comment" class="comment" v-html="item.comment" />
          <div v-for="n in item.tabs" :key="n">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div v-html="item.text" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CytCodeGroupEnum, CytologyModifierMap } from "@/modules/enums";
import cytologyService, { CytologyEntities } from "@/services/cytology";
import { mapState } from "vuex";

export default {
  name: "CytologyTextPreview",
  props: {
    selectedCodes: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: []
    };
  },

  watch: {
    selectedCodeKeys: {
      handler: function (newVal) {
        if (newVal?.length) {
          this.loadCodes(newVal);
        }
      },
      deep: true,
      immediate: true
    },
    selected: {
      handler(val) {
        if (!val) return;
        if (!val?.length) return;
        for (const code of val) {
          const selectedCodeIdx = this.selectedCodes.findIndex(c => {
            const id = c.id || c.cytDiagnosticCodeId;
            return id === code.id;
          });
          if (selectedCodeIdx > -1) {
            const selectedItem = this.selectedCodes[selectedCodeIdx];
            if (selectedItem) {
              code.comment = selectedItem.comment;
            }
          }
        }
      }
    }
  },
  methods: {
    async loadCodes(selection) {
      const codes = await Promise.all(
        selection.map(id =>
          cytologyService.getSingleEntity(CytologyEntities.CytDiagnosticCodes, id)
        )
      );
      this.selected = codes;
    }
  },
  computed: {
    ...mapState({
      adequacyTerm: state => state.labSettings.CytologyTermAdequacy,
      commentTerm: state => state.labSettings.CytologyTermComment,
      diagnosisTerm: state => state.labSettings.CytologyTermDiagnosis,
      recommendationTerm: state => state.labSettings.CytologyTermRecommendation,
      hormonalTerm: state => state.labSettings.CytologyTermHormonal
    }),
    enabled() {
      return !this.disabled;
    },
    selectedCodeKeys() {
      return this.selectedCodes.map(c => c.id || c.cytDiagnosticCodeId);
    },
    parentModifierMap() {
      return Object.keys(CytologyModifierMap).reduce((acc, key) => {
        acc[CytologyModifierMap[key]] = key;
        return acc;
      }, {});
    },
    typeMap() {
      return Object.keys(CytCodeGroupEnum).reduce((acc, key) => {
        const name = this.nameMap[CytCodeGroupEnum[key]];
        acc[CytCodeGroupEnum[key]] = name;
        return acc;
      }, {});
    },
    topCodes() {
      return [
        CytCodeGroupEnum.Adequacy,
        CytCodeGroupEnum.Diagnosis,
        CytCodeGroupEnum.Comment,
        CytCodeGroupEnum.GeneralCategorization,
        CytCodeGroupEnum.Hormonal,
        CytCodeGroupEnum.Recommendation
      ];
    },
    nameMap() {
      return {
        [CytCodeGroupEnum.Adequacy]: this.adequacyTerm || "Adequacy",
        [CytCodeGroupEnum.Diagnosis]: this.diagnosisTerm || "Diagnosis",
        [CytCodeGroupEnum.Comment]: this.commentTerm || "Comment",
        [CytCodeGroupEnum.GeneralCategorization]: "General Categorization",
        [CytCodeGroupEnum.Hormonal]: this.hormonalTerm || "Hormonal",
        [CytCodeGroupEnum.Recommendation]: this.recommendationTerm || "Recommendation"
      };
    },
    stringElements() {
      const elements = [];
      if (!Array.isArray(this.selected)) {
        return elements;
      }

      // Initialize groups first to maintain structure
      const groupedCodes = {};
      const groupElements = {};

      // First pass: Initialize groups for parent codes
      for (const code of this.selected) {
        const groupId = code.cytCodeGroupId;
        if (this.topCodes.includes(Number(groupId)) && !groupElements[groupId]) {
          const groupHeader = `**${this.typeMap[groupId]}**`;
          const groupItem = {
            tabs: 0,
            key: groupId,
            items: [],
            header: groupHeader
          };
          groupElements[groupId] = groupItem;
          elements.push(groupItem);
          groupedCodes[groupId] = [];
        }
      }

      // Second pass: Process items in original order
      for (const item of this.selected) {
        const groupId = item.cytCodeGroupId;

        // Handle parent code items
        if (this.topCodes.includes(Number(groupId))) {
          const codeItem = {
            tabs: 2,
            text: item.formattedText,
            id: item.id,
            key: item.id
          };

          groupElements[groupId].items.push(codeItem);

          if (item.comment) {
            groupElements[groupId].items.push({
              tabs: 2,
              text: item.comment,
              key: `${item.id}-comment`
            });
          }
        }
        // Handle modifiers
        else {
          const parentType = this.parentModifierMap[groupId];
          if (parentType && groupElements[parentType]) {
            const modifierElement = {
              tabs: 3,
              text: item.formattedText,
              key: item.id
            };
            groupElements[parentType].items.push(modifierElement);
          }
        }
      }

      return elements;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-preview {
  margin-top: 1rem;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 5px;
  background: hsl(50, 100%, 80%);
  padding: 1rem 0.5rem;
  position: relative;
}
.plus-icon {
  position: absolute;
  top: 0;
  right: 0;
}

::v-deep .diagnosis-code {
  padding: 0;
}
.name {
  font-size: 1.15rem;
  font-weight: bold;
}
.list {
  max-height: 300px;
  overflow-y: auto;
}

dl {
  display: flex;
  color: hsl(0, 0%, 40%);
  column-gap: 0.5rem;
}

dt {
  font-weight: 400;
  margin-bottom: 0.25rem;
}
</style>
