import api from "./api.js";

/**
 * Represents a service for handling cytology-related operations.
 */
/**
 * 
/**
 * @typedef {Object} CytologyResult
 * @property {number} id
 * @property {number} specimenId
 * @property {number} cytResultTypeId
 * @property {string} resultedBy
 * @property {string} resultedOn
 * @property {number} maxSeverityLevel
 * @property {boolean} isAbnormal
 * @property {boolean} agreesWithPrevious
 * @property {number} cytReviewTypeId
 * @property {number} cytReviewTypeRequestedId
 * @property {boolean} isMarkedForQC
 * @property {number} cytDiagnosticMacroId
 * @property {boolean} macroIsModified
 * @property {Array.<CytDiagnosticCode>} diagCodes
 */

/**
 * @typedef {Object} CytDiagnosticCode
 * @property {number} cytDiagnosticCodeId
 * @property {string} comments
 */

class CytologyResultsService {
  constructor() {
    this.url = "/api/CytResults";
  }

  createSearch() {
    return api.createSearch(this.url);
  }
  /**
   *
   * @param {CytologyResult} data
   * @returns
   */
  update(data) {
    return api.put(this.url, data);
  }
  /**
   *
   * @param {number} id
   * @returns
   */
  delete(id) {
    return api.delete(this.url + `/${id}`);
  }
  /**
   *
   * @param {CytologyResult} data
   * @returns
   */
  create(data) {
    return api.post(this.url, data);
  }
  /**
   *
   * @param {number} id
   * @returns {CytologyResult}
   */
  getOne(id) {
    return api.get(this.url + `/${id}`);
  }
}

const cytologyResultService = new CytologyResultsService();
export default cytologyResultService;
